.row {
  max-width: 100%; }

.menu {
  background-color: #1c2b63;
  padding: .06rem 0rem;
  height: auto; }
  .menu .inline-list {
    text-align: center; }
    .menu .inline-list li {
      float: none;
      display: inline-block; }
    .menu .inline-list a {
      color: #fff;
      padding-bottom: 0.5rem; }
      .menu .inline-list a:hover {
        color: #5fc0ec;
        transition: all .3s ease-in-out; }
    .menu .inline-list .active a {
      color: #5fc0ec; }
  .menu .account {
    position: absolute;
    top: 3%;
    right: 3%; }
    .menu .account a, .menu .account i {
      color: #fff; }
  @media only screen and (min-width: 40.063em) {
      .menu .inline-list {
        float: left;
        margin: 0 0 0 1.5rem; }
      .menu a:hover {
        border-bottom: 2px solid #5fc0ec; } }
  .menu .account-action {
    float: right;
    margin: -.75rem 0 0 0; }
    .menu .account-action li {
      margin-left: .2rem; }
    .menu .account-action a {
      padding: 0.75rem 1rem; }
    .menu .account-action a.signup {
      font-weight: bold;
      background-color: #3d4146;
      border-radius: 3px; }

.name {
  text-align: center;
  font-size: 1.5rem;
  line-height: 1;
  margin: 0 0 1rem 0;
  color: #fff;
}
.name i {
    color: #5fc0ec;
    margin-right: 0.5rem;
}
@media only screen and (min-width: 40.063em) {
    .name {
      float: left;
    }
}

#errorModal{
  width: 50%;
}

main {
  height: 95vh;
}

main > .row.border-bottom{
  height: 100%;
}

.border-bottom {
  border-bottom: 1px solid #1c2b63;
}

.border-right {
  border-right: 1px solid #000000;
}

.margin-left-0 {
  padding-left: 0;
}

.margin-right-0 {
  padding-right: 0;
}

.controls {
  display: flex;
  justify-content: center;
    margin-top: 7px;
    background: #EEE;
    margin-left: 5%;
}

.controls button {
  margin-bottom: 0.5rem;
  margin-right: 1rem;

  /*Copying iframe styles*/
  padding: 10px 20px;
  font-size: .8rem;
  border-radius: 6px;
}

#run {
    background-color: #38b333;
    /*border: 3px solid rgba(232,74,93, 1);*/
    color: #ffffff;
    letter-spacing: 1px;
}

#undo {
    background-color: #fbb800;
    color: #ffffff;
    letter-spacing: 1px;
}

#revert {
    background-color: #c83f1b;
    /*border: 3px solid rgba(232,74,93, 1);*/
    color: #ffffff;
    letter-spacing: 1px;
}

#showHints, #undo, #levelMenu {
    background-color: #1c2b63;
    /*border: 3px solid rgba(232,74,93, 1);*/
    color: #ffffff;
    letter-spacing: 1px;
}

.joyride-highlight{
  border: 3px solid red !important;
}

/*FLEXBOX*/
.preview_container {
  display: flex;
  justify-content: center;
  min-width: 500px;
  /*height: 100%;*/
  height: 550px;
  position: relative;
}

/*IFRAME CONTROLS*/
.iframe-controls #previous,
.iframe-controls #next,
.iframe-controls #showDialogue,
.iframe-controls #skipToSandbox,
.iframe-controls #backToGame,
.iframe-controls #publish {
    padding: 10px 20px;
    font-size: .8rem;
    border-radius: 6px;
}

.iframe-controls #previous,
.iframe-controls #next {
    /*background-color: rgba(239, 239, 239, 0.6);*/
    background-color: #24a4cd;
    /*border: 3px solid rgba(12, 111, 143, 1);*/
    color: #ffffff;
}

.iframe-controls #showDialogue {
    background-color: #c83f1b;
    /*border: 3px solid rgba(232,74,93, 1);*/
    color: #ffffff;
    letter-spacing: 1px;
}



.iframe-controls #skipToSandbox {
    background-color: #fbb800;
    /*border: 3px solid rgba(230,96,0, 1);*/
    color: #ffffff;
    letter-spacing: 1px;
}

.iframe-controls #backToGame {
    background-color: #5fc0ec;
    /*border: 3px solid rgba(230,96,0, 1);*/
    color: #ffffff;
    letter-spacing: 1px;
}

.iframe-controls #publish {
    background-color: #7b50bd;
    /*border: 3px solid rgba(230,96,0, 1);*/
    color: #ffffff;
    letter-spacing: 1px;
}

.iframe-controls{
    display: flex;
    justify-content: center;
    padding-top: 7px;
}

.iframe-controls button{
    margin: 0 1rem .5rem;
}
